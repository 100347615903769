<template>
  <router-link
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
    class="menu-link"
    :to="to"
  >
    <span
      :class="{
        'threeD-content': content === '3D TOUR',
      }"
      >{{ content }}</span
    >
    <img
      v-if="content === 'PORTFOLIO' && !isHover"
      src="@/images/menu/menubg.png"
    />
    <img
      v-else-if="content === '3D TOUR'"
      src="@/images/menu/menubg2hover.png"
    />
    <img
      v-else-if="content === 'PORTFOLIO' && isHover"
      src="@/images/menu/menubghover.png"
    />
    <img
      v-else-if="content !== 'PORTFOLIO' && !isHover"
      src="@/images/menu/menubg2.svg"
    />
    <img v-else src="@/images/menu/menubg2hover.png" />
  </router-link>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "MenuLink",
  props: {
    to: String,
    content: String,
  },
  setup(props) {
    const { to, content } = props;
    const isHover = ref(false);
    return {
      to,
      content,
      isHover,
    };
  },
};
</script>

<style lang="scss">
.menu-link {
  width: 100%;
  text-decoration: none;
  position: relative;
  transition: 0.2s transform;

  & > img {
    width: 100%;
  }

  & > span {
    position: absolute;
    top: calc(50% + 13px);
    transform: translateY(-50%);
    font-family: "Red Rose";
    padding-left: 30px;
    color: $white;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 3px;

    &.threeD-content {
      color: $neon;
    }

    @include RWD($mediumLaptop) {
      top: calc(50% + 8px);
      font-size: 30px;
      line-height: 38px;
    }

    @include RWD($tablet) {
      font-size: 34px;
      line-height: 42px;
      letter-spacing: 2px;
    }
  }

  &:nth-child(1) {
    z-index: 10;

    & > span {
      top: 50%;
    }
  }

  @for $i from 2 through 7 {
    &:nth-child(#{$i}) {
      z-index: 10 - $i;
      margin-top: -6%;
    }
  }

  &:hover {
    transform: translateX(-10px);

    @include RWD($tablet) {
      transform: translateX(0);
    }

    & > span {
      color: $neon;
    }
  }
}
</style>
