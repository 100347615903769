import { fetchAll, fetch } from "./request";

const endpoint = 'wp-json/wp/v2/event'

/**
 * Get all events
 */
 export async function getEvents() {
  const { data } = await fetchAll(endpoint)
  
  return data.map(o => transformData(o))
}

/**
 * Get one event
 */
export async function getEvent(id) {
  const { data } = await fetch(`${endpoint}/${id}`)
  
  return transformData(data)
}

function transformData (source) {
  let coverImage = null

  if (source.meta_box.link) {
    const match = source.meta_box.link.match('[\\?&]v=([^&#]*)')
    const videoId = match === null ? undefined : match[1]
    coverImage = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
  }

  return {
    event_id: source.id,
    title: source.title.rendered,
    date: source.meta_box.date.replaceAll('-','.'),
    lecture_intro: source.meta_box.lecture_intro,
    speaker_intro: source.meta_box.speaker_intro,
    video_chapter: source.meta_box.video_chapter,
    other_information: source.meta_box.other_information,
    link: source.meta_box.link,
    coverImage,
    type: source.event_category.length ? source._embedded["wp:term"][0][0].name : null
  }
}