<template>
  <button
    :class="{ white: isBackgroundWhite }"
    class="menu-button"
    v-show="!isMenuOpen"
    @click="isMenuOpen = true"
  >
    MENU
  </button>
  <teleport to="#portal-target">
    <transition name="bounce">
      <div v-if="isMenuOpen" class="guide-modal">
        <div class="modal-box">
          <button
            :class="{ white: props.isBackgroundWhite }"
            class="close-button"
            @click="isMenuOpen = false"
          >
            CLOSE
          </button>
          <div class="mask" />
          <div
            class="content-box"
            ref="modalRef"
            :style="{
              transform: `matrix3d(1, 0, 0, ${modalRefStyle.x}, 0, 1, 0, ${modalRefStyle.y}, 0, 0, 1, 0, 0, 0, 0, 1)`,
            }"
          >
            <section class="left">
              <div class="logo">
                <img src="@/images/menu/logo.png" alt="logo" />
                <img
                  src="@/images/menu/school.png"
                  alt="Taiwan Tech Architecture"
                />
              </div>
              <div class="key-visual">
                <img
                  v-if="media === 'mobile'"
                  src="@/images/menu/key-visual.png"
                  alt="key visual"
                />
                <img
                  v-else
                  src="@/images/menu/key-visual-desktop.png"
                  alt="key visual"
                />
              </div>
            </section>
            <section class="right">
              <nav>
                <MenuLink to="/portfolios" content="PORTFOLIO" />
                <MenuLink to="/3d-tour" content="3D TOUR" />
                <MenuLink to="/advisors" content="ADVISOR" />
                <MenuLink to="/events" content="EVENTS" />
                <MenuLink to="/sponsors" content="SPONSOR" />
                <MenuLink to="/map-index" content="MAP INDEX" />
                <MenuLink to="/products" content="PRODUCT" />
              </nav>
            </section>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { inject, ref, watch, onMounted, reactive, computed } from "vue";
import { useRoute } from "vue-router";
import MenuLink from "./link";
export default {
  name: "Guide Menu",
  components: { MenuLink },
  props: {
    isBackgroundWhite: Boolean,
  },
  setup(props) {
    const media = inject("media");
    const isMenuOpen = ref(false);
    const route = useRoute();
    const modalRef = ref(null);

    const modalRefStyle = reactive({
      x: 0,
      y: 0,
    });

    onMounted(() => {
      document.addEventListener("mousemove", handleMouseMove);
    });

    const modalRefCenter = computed(() => {
      if (modalRef.value) {
        const { left, width, top, height } =
          modalRef.value.getBoundingClientRect();

        return {
          posX: left + width / 2,
          posY: top + height / 2,
        };
      }

      return {
        posX: 0,
        posY: 0,
      };
    });

    const handleMouseMove = (e) => {
      // Reference: https://dev.to/cydstumpel/using-event-listeners-to-create-better-animations-3c7
      const clientX = e.clientX;
      const clientY = e.clientY;
      modalRefStyle.x = (clientX - modalRefCenter.value.posX) * 0.0000001;
      modalRefStyle.y = (clientY - modalRefCenter.value.posY) * 0.0000001;
    };

    watch(
      () => route.name,
      () => {
        isMenuOpen.value = false;
      }
    );

    watch(isMenuOpen, (isMenuOpen) => {
      const app = document.querySelector("#app");
      if (isMenuOpen) {
        app.style.filter = "blur(8px)";
        document.body.style.overflow = "hidden";
        document.body.style.height = "100vh";
      } else {
        app.style.filter = "unset";
        document.body.style.overflow = "auto";
        document.body.style.height = "auto";
      }
    });

    return {
      media,
      isMenuOpen,
      props,
      modalRef,
      modalRefStyle,
    };
  },
};
</script>

<style lang="scss" scoped>
.guide-modal {
  @include modal;

  .modal-box {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding: 60px;

    @include RWD($tablet) {
      margin: 0;
      padding: 20px 12px;
      max-height: 100%;
      overflow: auto;
    }
  }
}

.menu-button {
  font-family: "Zpix";
  font-size: 18px;
  letter-spacing: 2px;
  padding: 8px 30px;
  border: 1px solid $white;
  color: $white;
  z-index: 4;
  pointer-events: auto;

  @include RWD($mediumLaptop) {
    font-size: 12px;
    padding: 5px 20px;
  }

  @include RWD($tablet) {
    font-size: 12px;
    padding: 5px 20px;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.6),
      0 0 10px rgba(255, 255, 255, 0.3);
  }

  &:hover {
    opacity: 0.6;
  }
}

.menu-button.white {
  color: $black;
  border: 1px solid $black;
}

.close-button {
  font-family: "Zpix";
  font-size: 18px;
  letter-spacing: 2px;
  padding: 8px 30px;
  border: 1px solid $white;
  color: $white;
  z-index: 20;
  margin-left: auto;
  margin-bottom: 20px;

  @include RWD($mediumLaptop) {
    font-size: 12px;
    padding: 5px 20px;
  }

  @include RWD($tablet) {
    font-size: 12px;
    padding: 5px 20px;
    top: 20px;
    right: 12px;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.6),
      0 0 10px rgba(255, 255, 255, 0.3);
  }

  &:hover {
    opacity: 0.6;
  }
}

.close-button.white {
  color: $white;
  border: 1px solid $white;
}

.mask {
  display: none;

  @include RWD($tablet) {
    display: block;
    width: 100%;
    height: 50px;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0)
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 19;
  }
}

.content-box {
  display: flex;
  background-color: $black;
  padding: 65px;
  width: 85vw;
  max-width: 1500px;
  margin: auto;

  @include RWD($tablet) {
    display: block;
    padding: 0;
    background-color: transparent;
  }

  .left {
    flex-basis: 70%;
    margin-right: 40px;

    @include RWD($tablet) {
      margin-right: 0;
      margin-bottom: 12px;
    }

    .logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 35px;

      @include RWD($tablet) {
        width: 100%;
        flex-direction: column;
        margin-bottom: 24px;
      }

      img {
        width: 1px;
        flex: 1 1 auto;

        &:first-child {
          margin-right: 35px;
        }

        @include RWD($tablet) {
          width: 100%;

          &:first-child {
            margin-right: 0;
            margin-bottom: 24px;
          }
        }
      }
    }

    .key-visual {
      img {
        width: 100%;
      }
    }
  }

  .right {
    flex: 0 0 370px;

    nav {
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
    }

    @include RWD($mediumLaptop) {
      flex: 0 0 280px;
    }
  }
}

.bounce-enter-active {
  animation: bounceModel 0.5s cubic-bezier(1, 0.01, 0.8, -0.06) forwards;
}
.bounce-leave-active {
  animation: bounceModel 0.5s cubic-bezier(1, 0.01, 0.8, -0.06) reverse;
}

@keyframes bounceModel {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.05);
  }
  85% {
    transform: scaleY(1.05);
  }
  90% {
    transform: scaleY(1.05);
  }
  95% {
    transform: scaleY(0.95);
  }
  100% {
    transform: scaleY(1);
  }
}
</style>
