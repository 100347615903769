<template>
  <div class="app" :class="{ 'white-bg': isBackgroundWhite }">
    <Header v-if="!hideHeaderPage" :isBackgroundWhite="isBackgroundWhite" />
    <main>
      <router-view />
    </main>
    <teleport to="#portal-target">
      <transition @leave="onLeave">
        <LoadingSuspense v-if="loading" />
      </transition>
    </teleport>
    <Footer v-if="!hideFooterPage" :isBackgroundWhite="isBackgroundWhite" />
    <Cursor></Cursor>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, provide, ref } from "vue";
import { useRoute } from "vue-router";
import { mapState } from "vuex";
import Header from "@/components/organisms/AppHeader";
import Footer from "@/components/organisms/AppFooter";
import AppGridBackGrounds from "@/components/organisms/AppGridBackGrounds";
import LoadingSuspense from "@/components/organisms/LoadingSuspense";
import Cursor from "@/components/cursor/Cursor";
import { gsap } from "gsap";

export default {
  name: "App",
  components: { Header, Footer, LoadingSuspense, AppGridBackGrounds, Cursor },
  setup() {
    const route = useRoute();
    const windowWidth = ref(window.innerWidth);
    const media = computed(() => {
      if (!windowWidth.value) return "desktop";
      return windowWidth.value > 1024 ? "desktop" : "mobile";
    });

    provide("media", media);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(async () => {
      window.addEventListener("resize", handleResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
    });

    const hideHeaderPage = computed(() => {
      const hideHeaderFooterPages = ["/", "/home"];
      return hideHeaderFooterPages.includes(route.path);
    });

    const hideFooterPage = computed(() => {
      const hideHeaderFooterPages = ["/", "/3d-tour"];
      return hideHeaderFooterPages.includes(route.path);
    });

    const isBackgroundWhite = computed(() => {
      const whiteBackgroundPages = ["Sponsors", "EachPortfolio"];
      return whiteBackgroundPages.includes(route.name);
    });

    function onLeave(el, done) {
      gsap
        .to(el, {
          opacity: 0,
          duration: 1,
        })
        .then(done);
    }

    return {
      hideHeaderPage,
      hideFooterPage,
      isBackgroundWhite,
      onLeave,
    };
  },
  computed: {
    ...mapState(["loading"]),
  },
};
</script>

<style lang="scss">
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: hidden;
  background-color: $black;
  position: relative;

  &.white-bg {
    background-color: white;
  }

  main {
    width: 100%;
    margin: 0 auto;
    z-index: 2;
  }

  footer {
    margin-top: auto;
  }
}

button {
  padding: 0;
  outline: none;
  border: none;
}
</style>
