import axios from "axios";
// import { Message } from "element-ui";
import store from "@/store";
// import { getToken } from "@/utils/auth";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // timeout: 15000,
});

// service.interceptors.request.use(
//   (config) => {
//     if (store.getters.token) {
//       config.headers["Authorization"] = `Bearer ${getToken()}`;
//     }
//     return config;
//   },
//   (error) => {
//     console.log(error);
//     return Promise.reject(error);
//   }
// );

service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Message({
    //   message: error.message,
    //   type: "error",
    //   duration: 5 * 1000,
    // });
    return Promise.reject(error);
  }
);

export default service;