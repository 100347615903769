<template>
  <footer class="footer" :class="{ white: props.isBackgroundWhite }">
    <div class="contact">
      <span>TAIWAN TECH</span>
      <span>DEPT. OF ARCHITECTURE</span>
      <span>02-2733-3141 09:30-18:00</span>
    </div>
    <div class="social-platform">
      <a
        href="https://www.instagram.com/2021_ntustarch/"
        target="_blank"
        alt="instagram"
        >Instagram</a
      >
      <a
        href="https://www.facebook.com/NTUSTARCH.ThsisDesign"
        target="_blank"
        alt="facebook"
        >Facebook</a
      >
      <a
        href="https://www.youtube.com/channel/UCIguLRHHXCDClavBIv1jkag"
        target="_blank"
        alt="youtube"
        >Youtube</a
      >
    </div>
  </footer>
  <div class="grid-wrapper" :class="{ white: props.isBackgroundWhite }">
    <img
      v-if="props.isBackgroundWhite"
      class="grid"
      src="@/images/backgrounds/grids/bottom-light.png"
    />
    <img v-else class="grid" src="@/images/backgrounds/grids/bottom-dark.png" />
    <div class="mask" />
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  props: {
    isBackgroundWhite: Boolean,
  },
  setup(props) {
    return {
      props,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 200px 0 50px;
  z-index: 2;
  pointer-events: none;

  .contact {
    pointer-events: initial;
    & > span {
      display: block;
      text-align: center;
      font-family: "Zpix";
      font-size: 20px;
      line-height: 35px;
      color: $white;
      letter-spacing: 0.05em;

      @include RWD($mediumLaptop) {
        font-size: 14px;
        line-height: 28px;
      }

      @include RWD($tablet) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .social-platform {
    margin-top: 30px;
    pointer-events: initial;

    & > a {
      display: inline-block;
      text-decoration: none;
      padding: 5px 10px;

      font-family: "Zpix";
      font-size: 25px;
      font-weight: 500;
      line-height: 35px;
      letter-spacing: 0.05em;

      color: $white;

      & + a {
        margin-left: 45px;
      }

      @include RWD($mediumLaptop) {
        font-size: 18px;
        line-height: 35px;
      }

      @include RWD($tablet) {
        font-size: 16px;
        line-height: 16px;

        & + a {
          margin-left: 21px;
        }
      }
    }
  }
}

.grid-wrapper {
  position: absolute;
  width: 100%;
  bottom: 50px;
  left: 0;
  z-index: 0;

  .grid {
    width: 100%;
  }

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 40px;
    left: 0;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0)
    );
  }
}

.footer.white {
  .contact {
    & > span {
      color: $black;
    }
  }

  .social-platform {
    margin-top: 30px;

    & > a {
      color: $black;
    }
  }
}

.grid-wrapper.white {
  .mask {
    top: 0px;
    background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 1),
      rgba(0, 0, 0, 0)
    );
  }
}
</style>
