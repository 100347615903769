<template>
  <div class="background">
	  <div class="top">
		  <img src="@/images/backgrounds/grids/top-dark.png" />
	  </div>
	  <div class="bottom">
		  <img src="@/images/backgrounds/grids/bottom-dark.png" />
	  </div>
  </div>
</template>

<script>
export default {
  name: "AppGridBackGrounds",
  components: {},
  setup(props) {
	  const isHide = [];


    return {
    };
  },
};
</script>

<style lang="scss" scoped>
.background {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	z-index: 0;

	& > .bottom {
		margin-top: auto;
	}

	& img {
		width: 100%;
	}
}
</style>
