import { fetchAll, fetch } from "./request";

const endpoint = 'wp-json/wp/v2/student'

/**
 * Get all students
 */
export async function getStudents() {
  const { data } = await fetchAll(endpoint)
  
  return data.map((o, i) => transformData(o, i))
}

/**
 * Get one student
 * @param {string} id
 */
async function getStudent(id) {
  const { data } = await fetch(`${endpoint}/${id}`)
  
  return transformData(data)
}

function transformData (source, idx) {
  return {
    id: idx,
    student_id: source.id,
    name: source.meta_box.name,
    name_en: source.meta_box.name_en,
    advisor_id: source.meta_box.advise_by_to[0],
    work_id: source.meta_box.work?.[0],
    motto: source.meta_box.motto,
    ingredient: source.meta_box.ingredient,
    thought: source.meta_box.thought,
    keywords: source.meta_box.keywords,
    address: source.meta_box.address,
    coverImage: source.featured_media ? source._embedded["wp:featuredmedia"][0].source_url : null,
    // advisor,
    // portfolio: work
  }
}