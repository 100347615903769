import { fetchAll, fetch } from "./request";

const endpoint = 'wp-json/wp/v2/sponsor'

/**
 * Get all advisors
 */
export async function getSponsors() {
  const { data } = await fetchAll(endpoint)
  const formatted = data.map((o, i) => transformData(o, i))

  formatted.sort((s1,s2)=>{
    return parseInt(s1.sequence) - parseInt(s2.sequence)
  })
  
  return formatted
}

/**
 * Get one advisor
 * @param {string} id
 */
export async function getSponsor(id) {
  const { data } = await fetch(`${endpoint}/${id}`)
  
  return transformData(data)
}

function transformData (source, idx) {
  return {
    id: idx,
    sponsor_id: source.id,
    name: source.meta_box.name,
    is_special_thanks: Boolean(parseInt(source.meta_box.is_special_thanks)),
    url: source.meta_box.url,
    sequence: parseInt(source.meta_box.sequence),
    coverImage: source.featured_media ? source._embedded["wp:featuredmedia"]?.[0].source_url : null
  }
}