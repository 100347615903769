import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
  // withCredentials: true
})

instance.interceptors.request.use(
  function (request) {
    return request
  },
  function (error) {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function (response) {
    const { data, headers } = response
    return {
      data,
      pages: Number(headers['x-wp-totalpages']),
      total: Number(headers['x-wp-total'])
    }
  },
  function (error) {
    return Promise.reject(error)
  }
)

export async function fetch (endpoint, params = {}) {
  if (!endpoint) return

  try {
    const { data, pages, total } = await instance.get(
      endpoint,
      {
        params: {
          per_page: 50,
          _embed: true,
          ...params
        }
      }
    )
    
    return {
      data,
      pages,
      total,
      error: null
    }
  } catch (error) {
    console.error(error)

    return {
      data: [],
      pages: 1,
      total: 0,
      error
    }
  }
}

export async function fetchAll (endpoint, params = { page: 1 }, recursive = true) {
  let nextPageData = []
  const { data, pages, error } = await fetch(endpoint, params)
  const shouldProceed = !error && pages && recursive && (params.page !== pages)

  if (shouldProceed) {
    const { data } = await fetchAll(
      endpoint,
      { ...params, page: params.page + 1 },
      recursive
    )
    nextPageData = data
  }

  return {
    pages,
    data: data.concat(nextPageData),
    error
  }
}

export default instance
