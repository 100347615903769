import { createRouter, createWebHistory } from "vue-router";
import store from '@/store'

const routes = [
  {
    path: "/",
    name: "Landing",
    component: () => import("@/views/Landing"),
    beforeEnter: async () => {
      await store.dispatch("updateStudents")
    }
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/Home"),
    beforeEnter: async () => {
      await store.dispatch("updateStudents")
    }
  },
  {
    path: "/sponsors",
    name: "Sponsors",
    component: () => import("@/views/Sponsors"),
    beforeEnter: async () => {
      await store.dispatch('updateSponsors')
    }
  },
  {
    path: "/portfolios",
    name: "Portfolio",
    component: () => import("@/views/Portfolio"),
    beforeEnter: async () => {
      await Promise.allSettled([
        store.dispatch("updatePortfolios"),
        store.dispatch("updateStudents"),
        store.dispatch("updateAdvisors")
      ])
    }
  },
  {
    path: "/portfolios/:id",
    name: "EachPortfolio",
    component: () => import("@/views/Portfolio/EachPortfolio"),
    beforeEnter: async (to) => {
      await Promise.allSettled([
        store.dispatch("updatePortfolios"),
        store.dispatch("updateStudents"),
        store.dispatch("updateAdvisors"),
      ]);

      const validateData = store.getters.portfolios.find((p) => p.work_id == to.params.id)
      if (!validateData) return { name: 'Portfolio' }
    }
  },
  {
    path: "/products/:id?",
    name: "Product",
    component: () => import("@/views/Product"),
    beforeEnter: async () => {
      await store.dispatch('updateProducts')
    }
  },
  {
    path: "/events/:id?",
    name: "Event",
    component: () => import("@/views/Event"),
    beforeEnter: async () => {
      await store.dispatch('updateEvents')
    }
  },
  {
    path: "/advisors/:id?",
    name: "Advisor",
    component: () => import("@/views/Advisor"),
    beforeEnter: async () => {
      await Promise.all([
        store.dispatch("updateAdvisors"),
        store.dispatch("updateStudents"),
      ]);
    }
  },
  {
    path: "/map-index",
    name: "MapIndex",
    component: () => import("@/views/MapIndex"),
    meta: { keepLoading: true },
    beforeEnter: async () => {
      await store.dispatch("updatePortfolios")
    }
  },
  {
    path: "/3d-tour",
    name: "3DTour",
    component: () => import("@/views/ThreeDTour"),
    meta: { keepLoading: true },
    beforeEnter: async () => {
      await store.dispatch("updatePortfolios")
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  store.commit('setLoading', true)
})

router.afterEach((to, from) => {
  if (!to.meta.keepLoading) {
    store.commit('setLoading', false)
  }
  
  if (from.path !== to.path) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
});

export default router;
