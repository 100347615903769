export default {
  "公共服務類建築": {
      index: 1,
      en: "Civic & Government"
  },
  "文化類建築": {
      index: 2,
      en: "Culture"
  },
  "社區規劃": {
      index: 3,
      en: "Region Planning"
  },
  "環境及生態建築": {
      index: 4,
      en: "Environment & Ecology"
  },
  "照護及教育類建築": {
      index: 5,
      en: "Health Care & Education"
  },
  "改建再利用": {
      index: 6,
      en: "Refurbishment"
  },
  "交通運輸與基礎建設建築": {
      index: 7,
      en: "Infrastructure, Industry & Transportation"
  },
  "休閒娛樂類空間": {
      index: 8,
      en: "Leisure & Entertainment"
  },
  "區域規劃": {
      index: 9,
      en: "Urban Planning"
  },
  "住宅類建築": {
      index: 10,
      en: "Residence"
  },
  "商業類建築": {
      index: 11,
      en: "Commercial & Office"
  },
  "感受、精神場所及宗教空間A": {
      index: 12,
      en: "Expression, Spirit & Religion A"
  },
  "感受、精神場所及宗教空間B": {
      index: 13,
      en: "Expression, Spirit & Religion B"
  },
  "都會區域計畫": {
      index: 14,
      en: "Metropolitan Area Planning"
  },
  "園區規劃": {
      index: 15,
      en: "Various Park Planning"
  },
}