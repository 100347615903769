import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "bootstrap"; // Import js file
import "@/assets/css/style.scss";
import axios from "axios";
// import { createI18n } from "vue-i18n";
// import langs from "./assets/langs";
// import VueGtag from "vue-gtag";
import Bowser from "bowser";
import "./assets/js/preload";

const app = createApp(App);

// global var settings
window.router = router;

// Set up axios
window.axios = axios;
// window.Vue = Vue;

// Set up browser
const browser = Bowser.getParser(window.navigator.userAgent);
store.commit("setBrowser", browser.getBrowser());

// Set up i18n
// const locale = localStorage.getItem("lang") || "zh";
// const i18n = createI18n({
//   locale,
//   messages: langs,
// });
// app.use(i18n);

// Set up Google Analytics
// const GAID =
//   process.env.NODE_ENV === "development"
//     ? "UA-113459032-1"
//     : process.env.VUE_APP_GAID;

// app.use(VueGtag, {
//   config: {
//     id: GAID
//   },
//   includes: [{
//     id: 'UA-1234567-2' //multiple trackers
//   }]
// }, router);

app.config.productionTip = false;

app.use(store).use(router).mount("#app");
