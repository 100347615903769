import { createStore } from "vuex";
import { getPortfolios } from "@/api/portfolio";
import { getAdvisors } from "@/api/advisor";
import { getMedia } from "@/api/media"
import { getStudents } from "@/api/student"
import { deepClone } from "@/utils/utils"
import { getEvents } from "@/api/event"
import { getSponsors } from "@/api/sponsor"
import { getProducts } from "@/api/product"
import {categoryIconMap} from "./categoryMap"


export default createStore({
  state: {
    loading: true,
    global: {
      media: 'desktop',
    },
    dataIsReady: false,
    browser: {
      name: "",
      version: ""
    },
    portfoliosData: [],
    advisorsData: [],
    studentsData: [],
    events: [],
    sponsors: [],
    products: []
  },
  getters: {
    portfolios (state, getters) {
      return state.portfoliosData.map(o => ({
        ...o,
        student: getters.students.find(s => +s.work_id === +o.work_id) || {}
      }))
    },
    students (state) {
      return state.studentsData.map(o => ({
        ...o,
        advisor: state.advisorsData.find(a => +a.advisor_id === +o.advisor_id) || {}
      }))
    },
    advisors (state) {
      return state.advisorsData.map(o => ({
        ...o,
        students: state.studentsData.filter(s => +s.advisor_id === +o.advisor_id) || []
      }))
    }
  },
  mutations: {
    setBrowser(state, data) {
      state.browser = data
    },
    setDataIsReady(state, data) {
      state.dataIsReady = data
    },
    setEvents(state, data) {
      state.events = data
    },
    setPortfolios(state, data) {
      state.portfoliosData = data
    },
    setAdvisors(state, data) {
      state.advisorsData = data
    },
    setStudents(state, data) {
      state.studentsData = data
    },
    setSponsors(state, data) {
      state.sponsors = data
    },
    setProducts(state, data) {
      state.products = data
    },
    setLoading (state, data) {
      state.loading = data
    }
  },
  actions: {
    async updatePortfolios({ state }){
      if (state.portfoliosData.length) return

      const data = await getPortfolios()
      this.commit('setPortfolios', data)
    },
    async updateAdvisors({ state }){
      if (state.advisorsData.length) return
      
      const data = await getAdvisors()
      this.commit('setAdvisors', data)
    },
    async updateStudents ({ state }){
      if (state.studentsData.length) return

      const data = await getStudents()
      this.commit('setStudents', data)
    },
    updateStudentAdvisorRelation(){
      let state = this.state
      let advisors = deepClone(state.advisors)
      if(state.students.length<0 || advisors.length<0) return
      state.students.forEach(student => {
        let advisor = advisors.find(a => a.advisor_id == student.advisor_id)
        if(advisor) advisor.students.push(student)
      })
      this.commit('setAdvisors', advisors)
    },
    updateStudentPortfolioRelation(){
      let state = this.state
      let portfolios = deepClone(state.portfolios)
      if(state.students.length<0 || portfolios.length<0) return
      state.students.forEach(student => {
        let portfolio = portfolios.find(p => p.student_id == student.student_id)
        if(portfolio) portfolio.student = student
      })
      this.commit('setPortfolios', portfolios)
    },
    async updateEvents({ state }){
      if (state.events.length) return

      const data = await getEvents()
      this.commit('setEvents', data)
    },
    async updateSponsors({ state }){
      if (state.sponsors.length) return

      const data = await getSponsors()
      this.commit('setSponsors', data)
    },
    async updateProducts({ state }){
      if (state.products.length) return

      const data = await getProducts()
      this.commit('setProducts', data)
    }
  },
  modules: {}
});