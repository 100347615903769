import { fetchAll, fetch } from "./request";

const endpoint = 'wp-json/wp/v2/advisor'


/**
 * Get all advisors
 */
export async function getAdvisors() {
  const { data } = await fetchAll(endpoint)
  
  return data.map((o, i) => transformData(o, i))
}

/**
 * Get one advisor
 * @param {string} id
 */
export async function getAdvisor(id) {
  const { data } = await fetch(`${endpoint}/${id}`)
  
  return transformData(data)
}

function transformData (source, idx) {
  return {
    id: idx,
    advisor_id: source.id,
    name: source.meta_box.name,
    name_en: source.meta_box.name_en,
    experience: source.meta_box.experience,
    intro: source.meta_box.intro,
    topic_selection_direction: source.meta_box.topic_selection_direction,
    topic_direction: source.meta_box.topic_direction,
    // students: [],
    coverImage: source.featured_media ? source._embedded["wp:featuredmedia"]?.[0].source_url : null
  }
}