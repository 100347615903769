<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";

const props = defineProps({
  reverse: {
    default: true,
    type: Boolean,
  },
  parallax: {
    default: true,
    type: Boolean,
  },
  bgLineColor: {
    default: "33%, 50%, 139%",
    type: String,
  },
});

onMounted(() => {
  if (props.parallax) {
    document.addEventListener("scroll", handleScroll);
  }
});

onBeforeUnmount(() => {
  if (props.parallax) {
    document.removeEventListener("scroll", handleScroll);
  }
});

const scrollPosition = ref(0);
const handleScroll = () => {
  scrollPosition.value = document.documentElement.scrollTop * 0.05 - 200;
};
</script>

<template>
  <transition>
    <div
      :class="['grid-background', reverse ? 'reverse' : '']"
      :style="{
        '--offset': -scrollPosition + 'px',
        '--bg-line-color': bgLineColor,
      }"
    >
      <div class="grid-inner">
        <div class="grid"></div>
        <div class="grid"></div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.grid-background {
  position: fixed;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  perspective: 40vh;

  .grid-inner {
    --bg-line-color: #1b2395;
    --grid-size: 5vmax;
    position: absolute;
    left: 50%;
    top: 0;
    width: 200%;
    transform-origin: 50% 0;
    transform: translateX(-50%) translateY(var(--offset)) rotateX(-40deg);
    mask: radial-gradient(ellipse at 50% 0, #fff 10%, rgba(#fff, 0.3) 70%)
      no-repeat 50% 0% / 100% 200%;

    &::after {
      content: "";
      padding-top: 20%;
      display: block;
    }

    @media (max-width: 768px) {
      --grid-y-size: 8vmax;
      &::after {
        padding-top: 40%;
      }
    }

    .grid {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      animation: animatedGrid 10s linear infinite;
      background: linear-gradient(
            to bottom,
            var(--bg-line-color) 0,
            var(--bg-line-color) 0.15vmax,
            transparent 0.15vmax,
            transparent
          )
          repeat-y 50% 100% / 100% var(--grid-size),
        linear-gradient(
            to right,
            var(--bg-line-color) 0,
            var(--bg-line-color) 0.15vmax,
            transparent 0.15vmax,
            transparent
          )
          repeat-x 0 50% / var(--grid-size) 100%;

      &:last-child {
        top: 100%;
      }
    }

    &-2 {
      .grid-y {
        bottom: -3px;
        left: -4px;
        opacity: 0.4;
      }
    }
  }
}

@keyframes animatedGrid {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
</style>
