import { fetchAll, fetch } from "./request";

const endpoint = 'wp-json/wp/v2/product'

/**
 * Get all products
 */
export async function getProducts() {
  const { data } = await fetchAll(endpoint)
  
  return data.map((o, i) => transformData(o, i))
}

/**
 * Get one product
 * @param {string} id
 */
export async function getProduct(id) {
  const { data } = await fetch(`${endpoint}/${id}`)
  
  return transformData(data)
}

function transformData (source, idx) {
  return {
    id: idx,
    product_id: source.id,
    name: source.meta_box.name,
    name_en: source.meta_box.name_en,
    price: source.meta_box.price,
    description: source.meta_box.description,
    image: source.meta_box.images.length > 0 ? source.meta_box.images[0].full_url : null,
    images: source.meta_box.images.map(i=>i.full_url),
    coverImage: source.featured_media ? source._embedded["wp:featuredmedia"]?.[0].source_url : null
  }
}