<template>
  <div class="loading-page">
    <div class="loading-progress">
      <span class="percentage">{{ percentage }}</span>
      <div class="circle" />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch, onBeforeUnmount } from "vue";

export default {
  name: "Loading",
  setup() {
    const percentage = ref(0);
    let timer = null

    onMounted(() => {
      percentage.value = 0
      document.body.style.setProperty('overflow', 'hidden')
      setTimer()
    })

    onBeforeUnmount(() => {
      percentage.value = 100
      document.body.style.removeProperty('overflow')
      clearTimer()
    })

    watch(percentage, (count) => {
      if (count === 100) {
        clearTimer()
      }
    });

    function setTimer () {
      timer = setInterval(() => {
        percentage.value++;
      }, 70);
    }

    function clearTimer () {
      if (timer) {
        clearInterval(timer)
        timer = null
      }
    }

    return {
      percentage,
    };
  },
};
</script>

<style lang="scss" scoped>
.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 3000;
  user-select: none;
  background-color: $black;
}

.loading-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  position: relative;

  @include RWD($tablet) {
    width: 180px;
    height: 180px;
  }

  .percentage {
    font-family: "Zpix";
    font-size: 25px;
    color: $white;
  }

  .circle {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid $white;
    border-radius: 50%;

    animation: scale 3s infinite;
  }
  @keyframes scale {
    0% {
      transform: translate(-50%, -50%) scale(1);
    }

    50% {
      transform: translate(-50%, -50%) scale(1.2);
    }

    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

</style>
