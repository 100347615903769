import { fetchAll, fetch } from "./request";
import categoryMap from "@/assets/data/categoryMap"

const endpoint = 'wp-json/wp/v2/work'


/**
 * Get all advisors
 */
export async function getPortfolios() {
  const { data } = await fetchAll(endpoint)
  
  return data.map((o, i) => transformData(o, i))
}

/**
 * Get one advisor
 * @param {string} id
 */
export async function getPortfolio(id) {
  const { data } = await fetch(`${endpoint}/${id}`)
  
  return transformData(data)
}

function transformData (source, idx) {
  const category = source._embedded["wp:term"][0][0].name
  const coverImageItem = source.meta_box.images.find(image => !image.file.includes("_00.jpg") || !image.file.includes("_00.png"))

  return {
    id: idx,
    work_id: source.id,
    student_id: source.meta_box["work_by_student_from"][0],
    // student: {},
    name: source.meta_box.name,
    name_en: source.meta_box.name_en,
    description: source.meta_box.description,
    address: source.meta_box.address,
    dimension: source.meta_box.site_area_size,
    threeDWorkUrl: source.meta_box['3d_work_url'],
    software: source.meta_box.software,
    youtubeUrl: source.meta_box.youtube_url,
    personalImage: source.meta_box.images.filter(i => {
      return i.title.includes('_00') 
    }),
    images: source.meta_box.images.filter(
      // _00 是個人大頭照，不放在列表中
      i => !i.title.includes('_00')
      ).sort((i1,i2)=>{
        return i1.title.localeCompare(i2.title)
      }).map(i=>{
        return {image: i.full_url}
      }
    ),
    category,
    categoryNameEn: categoryMap[category].en,
    categoryIndex: categoryMap[category].index,
    site: source._embedded["wp:term"][1][0].name,
    // _00 是大頭照，取第一張能用的
    coverImage: coverImageItem ? coverImageItem.full_url : "https://data.whicdn.com/images/327433021/original.jpg"
  }
}