<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";

const cursorRef = ref(null);

defineProps(() => {});

onMounted(() => {
  document.addEventListener("mousemove", handleMove);
  document.addEventListener("mouseout", handleOut);
  document.addEventListener("touchmove", handleMove);
});

onBeforeUnmount(() => {
  document.removeEventListener("mousemove", handleMove);
  document.removeEventListener("mouseout", handleOut);
  document.removeEventListener("touchmove", handleMove);
});

const handleMove = (e) => {
  cursorRef.value.style.left = e.clientX + "px";
  cursorRef.value.style.top = e.clientY + "px";
  cursorRef.value.style.display = "block";
};

const handleOut = () => {
  cursorRef.value.style.display = "none";
};
</script>

<template>
  <div class="cursor" ref="cursorRef"></div>
</template>

<style lang="scss" scoped>
.cursor {
  position: fixed;
  z-index: 999999;
  background-color: #31fff9;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  pointer-events: none;
  box-shadow: 0 0 #31fff9, 0 0 60px #31fff9, 0 0 100px #31fff9;
  animation: colors 5s infinite;
  transform: translate(-50%, -50%);

  &::before {
    content: "";
    position: absolute;
    background-color: #31fff9;
    width: 50px;
    height: 50px;
    opacity: 0.2;
    transform: translate(-30%, -30%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }

  @keyframes colors {
    0% {
      filter: hue-rotate(0deg);
    }
    100% {
      filter: hue-rotate(340deg);
    }
  }
}
</style>
