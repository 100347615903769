<template>
  <header class="header" :class="{ white: isBackgroundWhite }">
    <router-link to="/home">
      <h1 v-if="!isBackgroundWhite" class="logo">
        <img
          class="white-normal"
          src="@/images/logo/headerLogo.png"
          alt="logo"
        />
        <img class="white-hover" src="@/images/logo/logohover.png" alt="logo" />
      </h1>
      <h1 v-else class="logo">
        <img src="@/images/logo/blacklogo.png" alt="logo" />
      </h1>
    </router-link>
    <AppGuide :isBackgroundWhite="props.isBackgroundWhite" />
  </header>
  <GridBackground
    bg-line-color="219, 219, 219"
    v-if="props.isBackgroundWhite && showGrids"
  ></GridBackground>
  <!--  <img-->
  <!--    v-if="props.isBackgroundWhite && showGrids"-->
  <!--    class="grid"-->
  <!--    src="@/images/backgrounds/grids/top-light.png"-->
  <!--    alt="decoration"-->
  <!--  />-->
  <!--  <img-->
  <!--    v-if="!props.isBackgroundWhite"-->
  <!--    class="grid"-->
  <!--    src="@/images/backgrounds/grids/top-dark.png"-->
  <!--    alt="decoration"-->
  <!--  />-->
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import AppGuide from "@/components/molecules/AppGuide";
import GridBackground from "@/components/grid-background/GridBackground";

export default {
  name: "Header",
  components: { AppGuide, GridBackground },
  props: {
    isBackgroundWhite: Boolean,
  },
  setup(props) {
    const route = useRoute();
    const showGrids = computed(() => {
      const list = ["Sponsors"];
      return !list.includes(route.name);
    });

    return {
      showGrids,
      props,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  width: 100%;
  z-index: 200;
  display: flex;
  padding: 60px 60px 0;
  justify-content: space-between;
  align-items: flex-start;
  pointer-events: none;
  height: 200px;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );

  @include RWD($tablet) {
    padding: 20px 12px;
    height: 75px;
  }

  & > a {
    z-index: 4;
    pointer-events: auto;
  }

  .logo {
    margin: 0;
    font-size: 0;
    img {
      width: 225px;

      @include RWD($mediumLaptop) {
        width: 170px;
      }

      @include RWD($tablet) {
        width: 120px;
      }

      &.white-normal {
        display: inline;
      }
      &.white-hover {
        display: none;
      }
    }

    &:hover {
      img.white-normal {
        display: none;
      }
      img.white-hover {
        display: inline;
      }
    }
  }
}

.grid {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.header.white {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 15%,
    rgba(255, 255, 255, 0)
  );
}
</style>
